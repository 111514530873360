// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"


//
//
// MOBILE MENUE
import Mmenu from "mmenu-js"

let menu

document.addEventListener("turbo:load", () => {
  if (menu) {
    menu.node = {}
    menu.node.menu = document.querySelector("#mobilemenu")
    menu._initMenu()
    menu._initPanels()
    menu._initOpened()
    menu.setPage()
  } else {

    menu = new Mmenu( "#mobilemenu", {
      "setSelected": {
        "parent": true
      },
      "navbar": {
        "title": "Menü"
      }
    }, {
      offCanvas: {
        page: {
          selector: ".mobile-helper"
        }
      }
    })
  }
})
